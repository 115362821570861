'use strict';

import EventDispatcher from "../EventDispatcher";
import { TweenMax, Expo, Cubic } from 'gsap';

class IndexView extends EventDispatcher
{
	
	/**
	 *
	 * 
	 */
	constructor()
	{
		super();
	}

	/**
	 *
	 * 
	 *  
	 */
	initialize()
	{
		this.display = 5;

		this.$main = $("#mainContent");

		// mainvisual
		this.$mainvisual = this.$main.children(".mainvisual");
		// this.$text = this.$mainvisual.find("#er > p > img.txt");
		this.$img = this.$mainvisual.find(".wrap > img");
		this.$ER = this.$mainvisual.find("#er > p > span");
		this.$loading = this.$mainvisual.children(".loading");
		this.current = 9999;
		// 画像のロード確認
		this.loaded = 0;
		for (let i = 0; i <this.$img.length; i++)
			this._imageLoader($(this.$img[i]).attr("src"));
	}

	/**
	 * 
	 * 
	 */
	_imageLoader(path)
	{
		let image = new Image();
		image.onload = (e) => { this._imageLoaded(); }
		image.src = `${path}`;
	}

	/**
	 * 
	 * 
	 * 
	 */
	_imageLoaded()
	{
		this.loaded ++;
		if (this.loaded == this.$img.length)
		{
			TweenMax.to(this.$loading, {
				autoAplha: 0,
				duration: 1,
				delay: 0.2,
				ease: Cubic.easeOut,
				onComplete: (e)=>{
					this.$loading.remove();
					TweenMax.to($("#er"), {
						opacity: 1,
						duration: 0.2,
						ease: Cubic.easeOut,
					});
					this._change();
				}
			});
		}
	}

	/**
	 * 
	 * 
	 * 
	 */
	_change()
	{
		let $_old = $(this.$img[this.current]);
		if ($_old[0])
		{
			TweenMax.to($_old, {
				autoAlpha:0,
				duration: 0.6,
				ease: Cubic.easeIn,
			});
		}
		this.$mainvisual.find(".active").removeClass("active");
		this.current ++;
		this.current = (this.current >= this.$img.length) ? 0 : this.current;

		let $_new = $(this.$img[this.current]);
		TweenMax.to($_new, {
			autoAlpha: 1,
			duration: 2,
			ease: Cubic.easeOut,
		});
		TweenMax.to($_new, {
			startAt: { scale: 1 },
			scale: 1.1,
			duration: this.display + 0.5,
			ease: "none",
		});

		// テキスト画像 表示切り替え
		// $(this.$text[this.current]).addClass("active");

		// ◯◯◯◯ERの適用
		this._shuffle($_new.data("text") );

		// 次のスライドキュー
		TweenMax.delayedCall(this.display, (e)=>{ this._change(); });
	}

	/**
	 * 
	 * 
	 * 
	 */
	_shuffle(str)
	{
		let count = 0,
			step = 0,
			to = str.length;
		//
		let up = setInterval((e)=>{
			step ++;
			if (step > 5) { step = 0; count ++; }
			// this.$ER.text(str.substr(0, count) + this._getString(to - count));
			this.$ER.text(str.substr(0, count));
			// インターバルクリア
			if (count == to) clearInterval(up);
		}, 15);
	}
	
	/**
	 * 
	 * 
	 * 
	 */
	_getString(leng)
	{
		const BASE = `ABCDEFGHIGKLMNOPQRSTUVWXYZ`;
		let str = ``;
		for (let i = 0; i < leng; i++) str += BASE[ BASE.length * Math.random() >> 0 ];
		return str;
	}


}
export default new IndexView();
