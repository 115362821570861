/**
 *
 *
 */
export default class EventDispatcher
{

	/**
	 *
	 * 
	 * 
	 */
	constructor()
	{
		this.listeners = {};
	}

	
	/**
	 *
	 * 
	 * 
	 */
	addEventListener (event, fn)
	{
		this.listeners[event.type] = this.listeners[event.type] || [];
		this.listeners[event.type].push(fn);
	}


	/**
	 *
	 * 
	 * 
	 */
	removeEventListener (event, fn)
	{
		let handlers = this.listeners[event.type]
		handlers.splice(handlers.indexOf(fn), 1)
	}


	/**
	 *
	 * 
	 * 
	 */
	dispatchEvent (event, ...args)
	{
		const eventListeners = this.listeners[event.type];
		if (eventListeners && eventListeners.length)
		{
			for (let i in eventListeners) {
				let listener = eventListeners[i];
				listener.apply(null, args);
			}
		}
	}


	/**
	 *
	 * 
	 * 
	 */
	hasEventListener (event)
	{
		return this.listeners[event]
	}


	/**
	 *
	 * 
	 * 
	 */
	clearEventHandlers (event)
	{
		if(event)
		{
			this.listeners[event] = []
		}
	}

}