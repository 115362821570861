window.$ = window.jquery = window.jQuery = require('jquery');
require('slick-carousel');
require("~/waypoints/lib/jquery.waypoints.min.js");
// require('bootstrap');
// require('popper.js');

// 
(function () {
    if (typeof window.CustomEvent === "function") return false;
    function CustomEvent(event, params) {
        params = params || { bubbles: false, cancelable: false, detail: undefined };
        var evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
    }
    CustomEvent.prototype = window.Event.prototype;
    window.CustomEvent = CustomEvent;
})();

import IndexView from './index/IndexView.js';
import './plugins';
import DetailView from './detail/DetailView.js';


$(function () {
    
    let $body = $("body");
    $("#navTrigger").click((e) => {
        if ($body.hasClass("navOn")) {
            $body.removeClass("navOn")
        }
        else {
            $body.addClass("navOn")
        }
    });
    let $navCloser = $("#modileGnav .closer");
    $navCloser.click((e)=>{
        if ($body.hasClass("navOn")) {
            $body.removeClass("navOn")
        }
    });
    

    var topBtn = $('#page-top');
    topBtn.hide();
    //スクロールが100に達したらボタン表示
    $(window).scroll(function () {
        if ($(this).scrollTop() > 300) {
            topBtn.fadeIn();
        } else {
            topBtn.fadeOut();
        }
    });
    //スクロールしてトップ
    topBtn.click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 500);
        return false;
    });


    let $mainContent = $("#mainContent");
    switch ($mainContent.attr("class"))
    {
        case "index":  IndexView.initialize(); break;
        // case "detail": DetailView.initialize(); break;
        default:
    }

    let isValidation = $("#validation")
    if (isValidation[0]) 
    {
        let methods = {
            tel: function (value, element) {
                return this.optional(element) || /^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/.test(value.replace(/[━.*‐.*―.*－.*\-.*ー.*\-]/gi, ''));
            }
        };
        $.each(methods, function (key) { $.validator.addMethod(key, this); });
        let $form = isValidation.next("form");
        $form.validate({
            rules: {
                会社名: { required: true },
                お名前: { required: true },
                お問い合わせ内容: { required: true },
                メールアドレス: {
                    required: true,
                    email: true
                },
                お電話番号: {
                    required: true,
                    tel: true
                }
            },

            //エラーメッセージの設定
            messages: {
                会社名: { required: '会社名を入力してください' },
                お名前: { required: 'お名前を入力してください' },
                お問い合わせ内容: { required: 'お問い合わせ内容を入力してください' },
                メールアドレス: {
                    required: 'メールアドレスを入力してください',
                    email: 'メールアドレスが正しく入力されていません'
                },
                お電話番号: {
                    required: 'お電話番号を入力してください',
                    tel: 'お電話番号が正しく入力されていません'
                },
            }
        });
    }

    $('.anima').waypoint(function(direction){
        var activePoint = $(this.element);
        //scroll down
        if (direction === 'down') {
            activePoint.addClass('active');
        }
    },{offset : '80%'});
    $('.fdu').waypoint(function(direction){
        var activePoint = $(this.element);
        //scroll down
        if (direction === 'down') {
            activePoint.addClass('active');
        }
    },{offset : '80%'});
    $('p:empty').remove();
});
