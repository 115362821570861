'use strict';

import EventDispatcher from "../EventDispatcher";

class DetailView extends EventDispatcher
{
	
	/**
	 *
	 * 
	 */
	constructor()
	{
		super();
	}

	/**
	 *
	 * 
	 *  
	 */
	initialize()
	{
		this._slickInit();
	}

	/**
	 * 
	 * 
	 * 
	 */
	_slickInit()
	{
		let $carouselLow = $("#carouselLow"),
			$slick = $("#slick"),
			$thumb = $("#slick_thumb");

		let low = String($carouselLow.html());
		let main = String(low).replace(/>/g, '></li>').replace(/<img/g, '<li class="photo"><img');
		let sub = String(low).replace(/>/g, '></a>').replace(/<img/g, '<a><img');


		$slick.html(main);
		$thumb.html(sub);

		let slick = $("#slick").slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
		});

        $("#slick-prev").click((e) => { slick.slick('slickPrev'); })
		$("#slick-next").click((e) => { slick.slick('slickNext'); })
	}
}
export default new DetailView();
